<template>
  <div class="commodity-apply-records">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item"
                             v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
      </div>
      <div class="filter">
        <!-- <div class="filter-item" @click="warehouseClick">
          <span>{{ warehouseComputed }}</span>
          <van-icon name="arrow-down" />
        </div> -->
        <div class="filter-item"
             @click="statusClick">
          <span>{{ statusComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <van-popup v-model="showStatus"
                 round
                 position="bottom">
        <custom-popup title="审核状态"
                      value-key="label"
                      :columns="statusColumns"
                      @cancel="showStatus = false"
                      @confirm="statusConfirm"></custom-popup>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id">
            <item-card class="list-item"
                       :title="item.applyNumber"
                       :status="parseStatus(item.status)">
              <div class="content"
                   @click="itemClick(item.id)">
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">申请人:</span>
                    <span class="value">{{ item.applicantName }}</span>
                  </div>
                  <div class="content-item">
                    <span class="name">提货人:</span>
                    <span class="value">{{ item.pickName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">请领单位:</span>
                    <span class="value">{{ item.organizationName }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">联系电话:</span>
                    <span class="value">{{ item.pickMobileNumber }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">提货日期:</span>
                    <span class="value">{{ item.pickTime }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">申请时间:</span>
                    <span class="value">{{ item.creationTime }}</span>
                  </div>
                </div>

                <div class="content-row">
                  <div class="content-item">
                    <span class="name">二维码:</span>
                    <span class="text-button"
                          @click.stop="getCodeBtn(item)">查看</span>
                  </div>
                </div>
              </div>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>

    <!-- 二维码 -->
    <van-dialog v-model="showDialog"
                title="二维码">
      <div style="text-align:center">
        <VueQr :margin='8'
               :size='280'
               :whiteMargin="true"
               :logoMargin="3"
               :logoCornerRadius="20"
               :text='codeInfo.applyNumber'> </VueQr>
        <div>
          <div>请领单号:{{codeInfo.applyNumber}}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityApplyRecords.less";
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find } from "lodash-es";
import VueQr from "vue-qr";

export default {
  name: "CommodityApplyRecords",
  data() {
    return {
      searchType: "applyNumber",
      searchTypeOptions: [
        { text: "请领单号", value: "applyNumber" },
        { text: "提货人", value: "pickName" }
      ],
      searchContent: "",

      filter: {
        warehouse: "",
        status: null,
        delivery: null
      },

      showWarehouse: false,
      warehouseColumns: [],
      showStatus: false,
      statusColumns: [
        {
          label: "所有",
          value: "all"
        },
        {
          label: "已通过",
          value: "APPROVED"
        },
        {
          label: "待审核",
          value: "UNDER_REVIEW"
        },
        {
          label: "已驳回",
          value: "REJECTED"
        },
        {
          label: "已过期",
          value: "EXPIRED"
        }
      ],
      listStatus: {
        loading: true,
        finished: false
      },

      list: [],
      page: {
        pageNumber: 0,
        pageSize: 10
      },

      showDialog: false,
      codeInfo: {}
    };
  },
  computed: {
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "仓库名称";
      let item = find(this.warehouseColumns, item => item.id == this.filter.warehouse);
      return item ? item.name : "仓库名称";
    },
    statusComputed() {
      if (!this.statusColumns.length) return "审核状态";
      let item = find(this.statusColumns, item => item.value == this.filter.status);
      return item ? item.label : "审核状态";
    }
  },
  methods: {
    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_KEEPER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = [{ id: "all", name: "全部仓库" }, ...ret.data];
    },
    async getClaimApplyList(sign = false) {
      let params = {
        useType: "CLAIMS_APPLY_USER",
        // startTime: this.filter.date.length ? `${this.filter.date[0]} 00:00:00`: null,
        // endTime: this.filter.date.length ? `${this.filter.date[1]} 23:59:59`: null,
        [this.searchType]: this.searchContent != "" ? this.searchContent : null,
        status: this.filter.status ? this.filter.status : null
      };
      let sort = [{ fieldName: "creationTime", direction: "DESC" }];
      params.sort = encodeURI(JSON.stringify(sort));

      let ret = await this.$api.getClaimApplyList({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery() {
      let filters = [];
      if (this.filter.delivery) {
        let query = Filter.attribute("pickStatus").equalTo("NEW");
        filters.push(query);
      }

      if (this.searchContent != "") {
        let query = Filter.attribute(this.searchType).contains(this.searchContent);
        filters.push(query);
      }
      let query = Filter.attribute("status").equalTo("APPROVED");
      filters.push(query);
      if (this.filter.warehouse && this.filter.warehouse != "all") {
        let query = Filter.attribute("warehouseId").equalTo(this.filter.warehouse);
        filters.push(query);

        if (filters.length == 1) {
          return filters[0].toString();
        } else if (filters.length > 1) {
          return Filter.AND(filters).toString();
        }
      } else {
        let outFilters = [];
        if (filters.length) {
          let query = Filter.AND(filters);
          outFilters.push(query);
        }

        let query = Filter.OR([Filter.attribute("WAREHOUSEIDS").equalTo("")]);
        outFilters.push(query);

        if (outFilters.length == 1) {
          return outFilters[0].toString();
        } else if (outFilters.length > 1) {
          return Filter.AND(outFilters).toString();
        }
      }
    },

    warehouseClick() {
      this.showWarehouse = true;
    },
    statusClick() {
      this.showStatus = true;
    },
    switchChange() {
      this.getInitialData();
    },
    warehouseConfirm(arg) {
      this.filter.warehouse = arg.id;
      this.showWarehouse = false;
      this.getInitialData();
    },
    statusConfirm(arg) {
      this.filter.status = arg.value == "all" ? null : arg.value;
      this.showStatus = false;
      this.getInitialData();
    },
    onSearch() {
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getClaimApplyList();
    },
    onLoad() {
      this.getClaimApplyList(true);
    },
    itemClick(id) {
      this.$router.push({
        name: "commodityPickingDetail",
        params: {
          id
        },
        query: {
          isDisable: true
        }
      });
    },
    addCommodity() {
      this.$router.push({
        name: "addCommodity"
      });
    },
    parseStatus(arg) {
      let status = {
        APPROVED: "已通过",
        UNDER_REVIEW: "待审核",
        REJECTED: "已驳回",
        EXPIRED: "已过期"
      };
      return status[arg];
    },
    getCodeBtn(row) {
      this.codeInfo = { applyNumber: row.applyNumber };
      this.showDialog = true;
    },
    init() {
      this.getInitialData();
    }
  },
  filters: {
    parseStatus(status) {
      if (status == "SUBMITTED") return "已出库";
      return "待出库";
    }
  },
  components: { VueQr },
  created() {
    this.getWarehouseList();
    this.getClaimApplyList();
  }
};
</script>

<style></style>
